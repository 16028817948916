.line-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.line-div hr{
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
    margin-top: 4px;
}
